import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"

const ListTitle = styled.h3`
  margin-bottom: 0.5em;
`

const ListItems = styled.ul`
  list-style-type: none;
  margin-left: 1em;
  li::before {
    content: "";
    background-color: hsla(0, 0%, 0%, 0.8);
    height: 4px;
    width: 4px;
    position: absolute;
    transform: translate(-12px, 11px);
    @media (prefers-color-scheme: dark) {
      background-color: hsla(0, 0%, 100%, 0.8);
    }
  }
`

const SkillsPage = location => (
  <Layout location={location}>
    <div>
      <ListTitle>Core skills</ListTitle>
      <ListItems>
        <li>Product Engineering</li>
        <li>Information Architecture</li>
        <li>Experience Design</li>
        <li>Interaction Design</li>
        <li>Hi-Fi Prototyping</li>
        <li>User Interface Engineering</li>
        <li>Development of Design Systems</li>
      </ListItems>
      <ListTitle>Software & tools</ListTitle>
      <ListItems>
        <li>HTML/CSS/JS</li>
        <li>React Native</li>
        <li>React JS</li>
        <li>Framer Motion</li>
        <li>Figma</li>
        <li>Sketch</li>
      </ListItems>
    </div>
  </Layout>
)

export default SkillsPage
